import React from 'react'
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faClock, faUser } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
       <MDBCard className="flex-fill mb-5">
          {this.props.link ? 
            <>
              {this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/' ? (
                <Link to={this.props.link}> {this.props.image && ( <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top" /> )} </Link>
              ) : (
                <a href={this.props.link} target="_blank" rel="noopener"> {this.props.image && ( <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top" /> )} </a>
              )}
            </>
            : 
            <>
              {this.props.image && (
                      <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top drop-shadow" />
              )}
            </>
          }


          <MDBCardBody className="card-blog-hp">
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium mt-2"> <Link to={this.props.link} className="effect-noline-blog">{this.props.title}</Link> </h3>
              {this.props.subtitle != '' && this.props.subtitle != null && (
                <h4 className="font-w-400 letter-spacing-1 text-xs-medium text-medium mt-2"> {this.props.subtitle} </h4>
              )}
            </div>
            <MDBCardText className="font-w-400 text-black text-medium mt-4 mb-2">
                {this.props.description.length <=160 ? this.props.description : this.props.description.substr(0, 160) + '...'}
            </MDBCardText>
          </MDBCardBody>


          <div className="mx-lg-auto mx-4 font-alt font-w-400 text-gray-light text-medium-small letter-spacing-1 py-4">
          <MDBRow>
            {(this.props.date) && 
            <MDBCol lg="auto" md="4" xs="12"> <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />{moment(this.props.date).format('YYYY/MM/DD')}</MDBCol>
            }
                
            {this.props.author && 
          <MDBCol lg="auto" md="4" xs="12"> <FontAwesomeIcon icon={faUser} className="mr-2" />{this.props.author}</MDBCol>
            }

            {this.props.readtime && 
          <MDBCol lg="auto" md="4" xs="12"> <FontAwesomeIcon icon={faClock} className="mr-2" />{this.props.readtime} read</MDBCol>
            }
          </MDBRow>
          </div>
       </MDBCard>
    )
  }
}

export default Card
